import { createRouter,createWebHashHistory } from "vue-router";
const routes = [
  {
    path:'/',
    name:'home',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/ABOUT',
    name:'ABOUT',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/TOKENMICS',
    name:'TOKENMICS',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/RANKSYSTEM',
    name:'RANKSYSTEM',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/GAMES',
    name:'GAMES',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/VIPCLUB',
    name:'VIPCLUB',
    component:()=>import('../views/home/index-home.vue')
  },
  {
    path:'/:catchAll(.*)',
    redirect: '/'
  }
]


const router = createRouter({
  history:createWebHashHistory(),
  routes,
  //地址栏输入定位锚点位置
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.name) {
      const targetElement = document.querySelector(`#${to.name}`);
      if (targetElement) {
        targetElement.scrollIntoView({behavior:'smooth'});
        return false; 
      } else {
        return { x: 0, y: 0, behavior: 'smooth' }
      }
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
})

//路由跳转执行平滑滚动到锚点位置
router.beforeEach((to,from,next)=>{
  if (to.name) {
    const targetElement = document.querySelector(`#${to.name}`);
    if (targetElement) {
      smoothScrollTo(targetElement, 1000); // 设置滚动时间（毫秒）
    }
  }
  next();
})

//平滑滚动函数
const smoothScrollTo = (targetElement, duration) => {
  const targetPosition = targetElement.offsetTop;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const startTime = performance.now();

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return distance / 2 * t * t + startPosition;
    t--;
    return -distance / 2 * (t * (t - 2) - 1) + startPosition;
  };

  const scroll = (currentTime) => {
    const elapsedTime = currentTime - startTime;
    window.scrollTo(0, easeInOutQuad(elapsedTime));

    if (elapsedTime < duration) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
export default router
