import { createApp } from 'vue'
import './style/style.css'
import './utils/rem.js'
import router from './router'
import App from './App.vue'
const app = createApp(App)
app.directive('animate', {
  mounted(el, binding) {
    const { top } = el.getBoundingClientRect()
    const h = window.innerHeight
    if (top < h) {
      addAnimationClass(el, binding.value)
    }
    const handleScroll = () => {
      const { top } = el.getBoundingClientRect()
      if (top < h) {
        addAnimationClass(el, binding.value)
        window.removeEventListener('scroll', handleScroll)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // 在unmounted钩子函数中引用handleScroll函数
    el._handleScroll = handleScroll
  },
  unmounted(el) {
    // 从window中移除scroll事件监听器
    window.removeEventListener('scroll', el._handleScroll)
  }
})

function addAnimationClass(el, classList) {
  if (Array.isArray(classList)) {
    el.classList.add(...classList)
  } else if (typeof classList === 'string') {
    el.classList.add(...classList.split(' '))
  }
}
app.use(router).mount('#app')

