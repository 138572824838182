/*
 * @LastEditors: shh
 * @Description: 
 * @Date: 2023-02-27 17:32:53
 * @LastEditTime: 2023-02-27 17:32:58
 * @FilePath: \findgrain-nav\src\rem\rem.js
 */
function remSize () {
  // 获取设备得宽度，以下代码所表达得是获取HTML元素得实际宽度和获取文档显示区的宽度
  // 如果有水平滚动条，则也包括滚动条的高度
  let devicenWidth = document.documentElement.clientWidth || window.innerWidth

  // 对设备的宽度进行判断，如果设备的宽度大于1920px的话则把它的宽度设为1920px
  // 移动端h5适配这里把宽度设为750
  if (devicenWidth >= 1920) {
    devicenWidth = 1920
  }
  // 对设备的宽度进行判断，设备小于最小宽度时
  // 移动端h5适配这里把宽度设为375
  if (devicenWidth <= 540) {
    devicenWidth = 540
  }
  // 这里设置的是把1rem设置为100px
  document.documentElement.style.fontSize = (devicenWidth / 19.2) + 'px'
  // 这里设置的是字体的默认大小为0.3rem
  document.querySelector('body').style.fontSize = 0.12 + 'rem'
}
remSize()
// 设置监听事件，当浏览器窗口大小发生改变的时候调用其中的函数
window.onresize = function () {
  remSize()
}
export default remSize()